import React, { useState, useEffect, useContext } from "react";
import Context from "../store/context";
import { Transition } from "@headlessui/react";
import { getReports } from "../api";
import Report from "./Report";
import Loading from "./Loading";
import { CloseIcon, ReloadIcon } from "./Icons";
import ReportsNavigation from "./ReportsNavigation";
import { datesAreEqual } from "../utils";

export default function Reports(props) {
  const [open, setOpen] = useState(false);
  const { globalState, globalDispatch } = useContext(Context);
  const { location } = globalState;

  const [reports, setReports] = useState({
    reports: null,
    loading: true,
    error: null,
  });
  const [report, setReport] = useState(null);

  useEffect(() => {
    setReports({ loading: true, data: null, error: null });
    async function doFetch() {
      try {
        const data = await getReports(location);
        const reports = data.reports.map((r) => {
          // Here we explicitly create date objects that are tz aware
          return {
            date: new Date(Date.parse(`${r.date}T00:00:00`)),
            id: r.id,
            timeframe: r.timeframe,
          };
        });
        setReports({
          loading: false,
          data: reports,
          error: null,
        });
        if (!report) {
          let pickedReport;
          if (globalState.date) {
            // if there is a report with the same date as the global date, pick that
            const globalDate = new Date(
              Date.parse(`${globalState.date.substr(0, 10)}T00:00:00`)
            );
            const globalReportMatch = reports.find((r) => {
              return datesAreEqual(r.date, globalDate);
            });
            if (globalReportMatch) {
              pickedReport = globalReportMatch;
            }
          }
          pickedReport = pickedReport ? pickedReport : reports[0];
          setReport(pickedReport);
        }
      } catch (error) {
        setReports({ loading: false, data: [], error: error });
        console.error(error);
      }
    }
    doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, globalState.reportRefetch]);

  const Navigation = (
    <ReportsNavigation
      reports={reports.data}
      report={report}
      setReport={setReport}
      setOpen={setOpen}
    />
  );

  return (
    <div className="h-screen flex overflow-hidden bg-nikedark relative">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <Transition show={open} className={"md:hidden"}>
        <div className="absolute inset-0 flex z-40">
          <Transition.Child
            enter="transition-opacity easeLinear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity easeLinear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute inset-0"
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </Transition.Child>
          <Transition.Child
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-white"
          >
            <div className="absolute top-0 right-0 -mr-12 pt-2">
              <Transition.Child
                as="button"
                enter="transition-opacity easeLinear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity easeLinear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                aria-hidden="true"
                onClick={() => {
                  setOpen(false);
                }}
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span className="sr-only">Close sidebar</span>
                {/* Heroicon name: x */}
                <CloseIcon />
              </Transition.Child>
            </div>
            <div className="flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">{Navigation}</nav>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-white md:flex md:flex-shrink-0">
        <div className="flex flex-col w-96">{Navigation}</div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <main
          className="flex-1 relative overflow-y-auto focus:outline-none p-4 sm:p-6 md:p-8"
          tabIndex="0"
        >
          <div className="max-w-7xl mx-auto min-h-full bg-white">
            {/* Replace with your content */}
            <div className="p-8">
              {reports.data ? (
                <div>
                  {report ? (
                    <Report
                      id={report.id}
                      location={location}
                      setOpen={setOpen}
                    />
                  ) : (
                    <Loading />
                  )}
                </div>
              ) : (
                <Loading />
              )}
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  );
}
