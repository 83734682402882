import React, { useContext } from "react";
import Context from "../store/context";
import DayPicker from "react-day-picker";
import Modal from "./Modal";
import styles from "./DateModal.module.css";
import Moment from "moment";

const DateModal = ({ value, onClose }) => {
  const { globalDispatch } = useContext(Context);
  const format = "YYYY-MM-DD";
  const date = new Moment(value, format);
  return (
    <Modal onClose={onClose}>
      <div className="mx-auto justify-">
        <DayPicker
          classNames={styles}
          onDayClick={(value) => {
            globalDispatch({
              type: "date",
              payload: new Moment(value).format(format),
            });
            onClose();
          }}
          modifiersStyles={{
            "--color-primary": "#3f5efb",
          }}
          initialMonth={date.toDate() || new Date()}
          selectedDays={[date.toDate()]}
        />
      </div>
    </Modal>
  );
};

export default DateModal;
