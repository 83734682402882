import React, { useContext } from "react";

import Context from "../store/context";
import Gate from "./Gate";

export default function App({ children }) {
  const { globalState, globalDispatch } = useContext(Context);

  return (
    <div>
      {globalState.presentGate && (
        <Gate
          onClose={() => {
            globalDispatch({ type: "presentGate", payload: false });
          }}
        />
      )}
      {children}
    </div>
  );
}
