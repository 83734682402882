import React, { useContext, useState } from "react";
import Context from "../store/context";
import { PlusIcon, MinusIcon } from "./Icons";
import { formatData } from "../utils";

const sections = [
  ["nrc", "Nike Running Club"],
  ["ntc", "Nike Training Club"],
  ["weather", "Weather"],
  ["snkrs", "SNKRS"],
  ["social", "Social"],
];

const Item = ({ isOpen, label, legendClassName, data, time }) => {
  const [isExpanded, toggleExpanded] = useState(false);
  return (
    <div className={`border-gray-100 ${isOpen ? "border-b" : ""} md:border-b`}>
      <button
        onClick={() => toggleExpanded(!isExpanded)}
        className="flex flex-row w-full items-center h-20"
      >
        <div
          className={`flex-grow ${
            isOpen ? "flex" : "hidden"
          } md:flex font-medium`}
        >
          {label}
        </div>
        <div className={`legend-mark ${legendClassName}`}></div>
        <div className={`pl-2 ${isOpen ? "flex" : "hidden"} md:flex`}>
          {isExpanded ? <MinusIcon /> : <PlusIcon />}
        </div>
      </button>
      {isExpanded && data && data.columns && (
        <div>
          {data.columns.map((value, index) => {
            return (
              <div
                key={index}
                className={`pb-4 ${isOpen ? "flex" : "hidden md:flex"}`}
              >
                <label
                  className="flex-grow"
                  dangerouslySetInnerHTML={{ __html: value }}
                ></label>
                <div className="inline">
                  {formatData(data.values[time][index])}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default function Data({ isOpen, onToggle }) {
  const { globalState } = useContext(Context);

  return (
    <div
      className={`overflow-y-scroll no-scrollbar border-gray-200 border-l ${
        isOpen ? "w-96" : "md:w-96"
      }`}
    >
      <div className="p-6 md:pt-0">
        <div className="flex flex-row items-center h-12 md:hidden">
          <div className="flex-grow"></div>
          <button
            onClick={onToggle}
            className={`${isOpen ? "rotate-180" : ""} transform transition-all`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2928 5.29289L12.707 6.70711L9.41414 10L12.707 13.2929L11.2928 14.7071L6.58571 10L11.2928 5.29289Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        {sections.map(function ([slug, label]) {
          const data = globalState.data[slug];
          return (
            <Item
              label={label}
              key={slug}
              legendClassName={`${slug}-gradient`}
              data={data || null}
              time={globalState.time}
              isOpen={isOpen}
            />
          );
        })}
      </div>
    </div>
  );
}
