import { useReducer } from "react";
import Moment from "moment";

const searchParams = new URLSearchParams(window.location.search);
const momentFormat = "YYYY-MM-DD";

const initialState = {
  date:
    searchParams.get("date") ||
    new Moment().add(-2, 'days').format(momentFormat),
  time: 0,
  data: {},
  stories: [],
  storyIndex: 0,
  requestURL: "",
  refetch: false, // if true, long cache-busting request is triggered
  reportRefetch: false, // if true, long cache-busting request is triggered
  presentGate: !searchParams.get("date"),
  location: searchParams.get("location") || "Seoul",
  extrapolated: searchParams.get("extrapolated") !== 'false'
};

const useGlobalState = (history) => {
  // set initial state

  const updateSearchParams = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);

    searchParams.set(key, value);
    history.push({
      search: "?" + searchParams.toString(),
    });
  };

  const deleteSearchParam = (key) => {
    let searchParams = new URLSearchParams(window.location.search);

    searchParams.delete(key);
    history.push({
      search: "?" + searchParams.toString(),
    });
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "filter":
        for (let [key, value] of Object.entries(action.payload)) {
          if (Array.isArray(value)) {
            // find duplicated
            let duplicates = value.filter((e, i, a) => a.indexOf(e) !== i);

            // remove all occurences
            action.payload[key] = value.filter(
              (e, i, a) => duplicates.indexOf(e) === -1
            );
          }

          updateSearchParams(key, action.payload[key]);
        }

        return { ...state, ...action.payload };

      case "location":
        state["location"] = action.payload;
        updateSearchParams("location", action.payload);
        return { ...state };

      case "date":
        if (action.payload instanceof Date) {
          action.payload = new Moment(action.payload).format(momentFormat);
        }
        state["date"] = action.payload;
        updateSearchParams("date", action.payload);
        return { ...state };

      case "extrapolated":
        state["extrapolated"] = action.payload;

        if (action.payload) {
          deleteSearchParam("extrapolated");
        } else {
          updateSearchParams("extrapolated", 'false');
        }

        return { ...state };

      default:
        state[action.type] = action.payload;
        return { ...state };
    }
  };

  function init(initialState) {
    updateSearchParams("date", initialState.date);
    updateSearchParams("location", initialState.location);
    return initialState;
  }
  const [globalState, globalDispatch] = useReducer(reducer, initialState, init);

  return { globalState, globalDispatch };
};

export default useGlobalState;
