const getUrl = (resource, location, options = {}) => {
  const {
    refetching = false,
    extrapolated = true,
  } = options;

  let reportUrl = `${process.env.REACT_APP_API_ROOT}/api${resource}?location=${location}`;

  if (!extrapolated) {
    reportUrl += `&extrapolated=false`;
  }

  if (refetching) {
    reportUrl += '&reload';
  }

  return reportUrl;
};

export const getReports = (location, options = {}) => {
  return fetch(getUrl("/reports/", location)).then((res) => res.json());
};

export const getReport = (location, id, options = {}) => {
  const url = getUrl(`/reports/${id}/`, location, options);
  return fetch(url).then((res) => res.json());
};
