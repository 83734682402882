import React, { useContext, useState, useEffect } from "react";
import Context from "../store/context";
import { Flipper, Flipped } from "react-flip-toolkit";

import "./StoryList.scss";

export default function StoryList(props) {
  const { globalState } = useContext(Context);
  const [storyRankings, setStoryRankings] = useState([]);
  const [storyMovement, setStoryMovement] = useState([]);

  const [stories, setStories] = useState([]);

  useEffect(() => {
    const storySorter = (a, b) => {
      var table = globalState.data.rankings[globalState.time];
      if (table === undefined) {
        return 0;
      }
      if (table[a["class_name"]] < table[b["class_name"]]) {
        return 1;
      }
      if (table[a["class_name"]] > table[b["class_name"]]) {
        return -1;
      }
      return 0;
    };
    if (
      globalState.data !== undefined &&
      Object.keys(globalState.data).length > 0 &&
      Object.keys(globalState.data.rankings).length > 0 &&
      Object.keys(globalState.data.movement).length > 0 &&
      globalState.data.rankings !== undefined &&
      globalState.stories.length > 0
    ) {
      setStoryRankings(globalState.data.rankings[globalState.time]);
      // console.log("data: ", globalState.data);
      // console.log("rankings: ", globalState.data.rankings);
      setStoryMovement(globalState.data.movement[globalState.time]);
      setStories(globalState.stories);
    } else {
      setStories(globalState.stories);
    }
  }, [globalState.stories, globalState.time, globalState.data]);

  return (
    <div className={`${props.className}`}>
      <div className={`mt-10 flex items-start`}>
        <Flipper
          flipKey={Object.values(storyRankings).join("")}
          spring={"noWobble"}
        >
          {stories.map((s, index) => {
            return (
              <Flipped flipId={s.class_name} key={s.class_name} stagger>
                <li
                  className="cursor-pointer block px-8 md:px-16"
                  onClick={() => {
                    props.onSelect(s);
                  }}
                >
                  <div className="flex mb-2">
                    <span
                      className={`${
                        storyMovement[s.class_name] > 0
                          ? "text-green-400"
                          : storyMovement[s.class_name] === 0
                          ? "text-gray-400"
                          : "text-red-400"
                      } flex-grow-0 flex-shrink-0 whitespace-no-wrap w-32 text-right pr-8 hidden`}
                    >
                      <span className="text-xs pr-2">
                        {storyMovement[s.class_name] > 0
                          ? "▲"
                          : storyMovement[s.class_name] === 0
                          ? "–"
                          : "▼"}
                      </span>
                      {storyMovement[s.class_name] !== undefined
                        ? Math.abs(storyMovement[s.class_name])
                        : ""}
                    </span>
                    <h2
                      className="font-light text-xl md:text-3xl mb-6 md:leading-loose"
                      dangerouslySetInnerHTML={{
                        __html: s.story[globalState.time]["micro"], // TODO remove legacy macro
                      }}
                    ></h2>
                  </div>
                </li>
              </Flipped>
            );
          })}
        </Flipper>
      </div>
    </div>
  );
}
