import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Context from "../store/context";

import "./Navigation.scss";

export default function Navigation(props) {
  const { globalState, globalDispatch } = useContext(Context);
  let history = useHistory();
  let routerLocation = useLocation();

  // We want to carry location and date parameters between pages
  const getURLQueryParamString = () => {
    let searchParams = new URLSearchParams(window.location.search);
    ["date", "location"].forEach((key) => {
      searchParams.set(key, globalState[key]);
    });
    return searchParams.toString();
  };

  return (
    <div className="flex flex-row w-full h-20 border-b relative">
      <div className="h-full border-r flex content-center flex-wrap justify-center px-6">
        <svg
          width="32"
          height="12"
          viewBox="0 0 32 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.53175 11.4008C2.58028 11.3671 1.79722 11.1061 1.19097 10.6177C1.07309 10.5251 0.795229 10.2472 0.702608 10.1293C0.458426 9.8178 0.290025 9.51468 0.172144 9.17787C-0.173079 8.1422 0.00374222 6.78657 0.677348 5.29622C1.25833 4.02479 2.15086 2.76178 3.70857 1.01041C3.93591 0.749386 4.61794 0 4.62636 0C4.62636 0 4.59268 0.0673603 4.54216 0.143141C4.138 0.825166 3.79277 1.61665 3.59911 2.31552C3.29599 3.42697 3.32967 4.37844 3.70857 5.1194C3.96959 5.62461 4.40744 6.07087 4.90422 6.31505C5.77149 6.74447 7.05134 6.77815 8.60063 6.41609C8.71009 6.39083 14.0147 4.98468 20.3887 3.28383C26.7796 1.58297 32 0.193662 32 0.193662C32 0.193662 17.1807 6.53397 9.48474 9.82622C8.26383 10.3483 7.94387 10.4746 7.3713 10.6766C5.90621 11.1903 4.59268 11.4429 3.53175 11.4008Z"
            fill="#06080D"
          />
        </svg>
      </div>
      <div className="h-full flex flex-grow border-r content-center flex-wrap justify-center sm:justify-start">
        <div className="text-lg px-8 py-6 items-center hidden sm:flex">
          Sport Pulse
        </div>
        <div className="m-0 flex flex-row h-full sections">
          <a
            href="./"
            className={`h-full text-center flex items-center relative`}
            aria-current={
              routerLocation.pathname === "/" || routerLocation === undefined
                ? "page"
                : undefined
            }
            onClick={(e) => {
              e.preventDefault();
              history.push(`/?${getURLQueryParamString()}`);
            }}
          >
            <div className="px-6">Explore</div>
          </a>
          <a
            href="./"
            className={`h-full text-center flex items-center relative`}
            aria-current={
              routerLocation.pathname === "/reports" ? "page" : undefined
            }
            onClick={(e) => {
              e.preventDefault();
              history.push(`/reports?${getURLQueryParamString()}`);
            }}
          >
            <div className="px-6">Reports</div>
          </a>
        </div>
      </div>
      <div className="h-full flex content-center px-6 items-center">
        <button
          className="text-lg block"
          onClick={() => {
            globalDispatch({ type: "presentGate", payload: true });
          }}
        >
          <span className="hidden md:inline mr-3">{globalState.location}</span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="inline"
          >
            <path
              d="M5.05049 4.06245C6.36331 2.74188 8.14388 2 10.0005 2C11.8571 2 13.6377 2.74188 14.9505 4.06245C16.2633 5.38301 17.0008 7.17407 17.0008 9.04163C17.0008 10.9092 16.2633 12.7003 14.9505 14.0208L10.0005 19L5.05049 14.0208C4.4004 13.367 3.88472 12.5907 3.5329 11.7364C3.18107 10.882 2.99998 9.96636 2.99998 9.04163C2.99998 8.1169 3.18107 7.20122 3.5329 6.34689C3.88472 5.49255 4.4004 4.7163 5.05049 4.06245ZM10.0005 11.0534C10.5309 11.0534 11.0396 10.8415 11.4147 10.4642C11.7898 10.0869 12.0005 9.57519 12.0005 9.04163C12.0005 8.50807 11.7898 7.99636 11.4147 7.61908C11.0396 7.2418 10.5309 7.02984 10.0005 7.02984C9.47006 7.02984 8.96135 7.2418 8.58628 7.61908C8.2112 7.99636 8.00049 8.50807 8.00049 9.04163C8.00049 9.57519 8.2112 10.0869 8.58628 10.4642C8.96135 10.8415 9.47006 11.0534 10.0005 11.0534Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
