import React, { useEffect } from "react";
import useGlobalState from "./useGlobalState";
import Context from "./context";

const GlobalStateProvider = ({ history, children }) => {
  const globalContext = useGlobalState(history);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    let requestURL =
      process.env.REACT_APP_API_ROOT + "/api/search/?" + params.toString() + (globalContext.globalState.refetch ? "&reload" : "");
    globalContext.globalDispatch({ type: "requestURL", payload: requestURL });
    globalContext.globalDispatch({ type: "storyIndex", payload: null });

    fetch(requestURL)
      .then((res) => res.json())
      .then(
        (result) => {
          globalContext.globalDispatch({
            type: "stories",
            payload: result.stories,
          });
          globalContext.globalDispatch({ type: "storyIndex", payload: 0 });
          globalContext.globalDispatch({ type: "data", payload: result.data });
          globalContext.globalDispatch({ type: "refetch", payload: false });
        },
        (error) => {}
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
      globalContext.globalState.date,
      globalContext.globalState.location,
      globalContext.globalState.refetch,
      globalContext.globalState.extrapolated
  ]);

  return <Context.Provider value={globalContext}>{children}</Context.Provider>;
};

export default GlobalStateProvider;
