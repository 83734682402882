import React, { useContext } from "react";
import Context from "../store/context";
import Modal from "./Modal";
import Toggle from "./Toggle";
import { LOCATIONS } from "../locations.config";

const SelectedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="3" fill="white" />
    </svg>
  );
};

const UnselectedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="2.25" stroke="#9CA3AF" strokeWidth="1.5" />
    </svg>
  );
};

export default function Gate({ onClose }) {
  const { globalState, globalDispatch } = useContext(Context);
  return (
    <Modal onClose={onClose}>
      <div className="p-4">
        <div>
          {LOCATIONS
            .filter(({ isDisabled }) => {
              return !isDisabled;
            })
            .map(({ value, label, code }) => {
              return (
                <button
                  key={label}
                  onClick={() => {
                    globalDispatch({ type: "location", payload: value });
                    onClose();
                  }}
                  className={`flex w-full items-center text-lg sm:text-4xl sm:leading-relaxed hover:text-white ${
                    globalState.location === value
                      ? "text-white"
                      : "text-gray-400"
                  }`}
                >
                  <div className="text-left font-medium flex-grow pr-4">
                    {label}
                  </div>
                  <div className="text-lg">{code}</div>
                  <div className="w-8 sm:w-16 text-right flex flex-none justify-end">
                    {globalState.location === value ? (
                      <SelectedIcon />
                    ) : (
                      <UnselectedIcon />
                    )}
                  </div>
                </button>
              );
            })}
        </div>
        <div className="border-t border-gray-800 mt-4 pt-8 text-gray-700">
          <div className="uppercase mb-4">Coming Soon</div>
          {LOCATIONS
            .filter(({ isDisabled }) => {
              return !!isDisabled;
            })
            .map(({ value, label, code }) => {
              return (
                <div
                  key={label}
                  className="flex items-center text-lg sm:text-4xl sm:leading-relaxed"
                >
                  <div className="font-medium flex-grow pr-4">{label}</div>
                  <div className="text-lg">{code}</div>
                  <div className="w-8 sm:w-16"></div>
                </div>
              );
            })}
        </div>

        <div className="flex justify-between border-t border-gray-800 mt-4 pt-8">
          <p className="text-gray-400 leading-loose">Data extrapolation</p>
          <Toggle
              name="extrapolation-toggle"
              values={['Enabled', 'Disabled']}
              onChange={(extrapolated) => {
                  globalDispatch({
                      type: "extrapolated",
                      payload: extrapolated === 'Enabled',
                  })
              }}
              selected={globalState.extrapolated ? 'Enabled' : 'Disabled'}
          />
        </div>
      </div>
    </Modal>
  );
}
