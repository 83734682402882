import React from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";

// Styles
import "./index.css";
import "./styles/App.scss";

import GlobalStateProvider from "./store/GlobalStateProvider";

// Components
import App from "./components/App";
import Story from "./components/Story";
import Reports from "./components/Reports";
import Navigation from "./components/Navigation";


const history = createBrowserHistory();

const routing = (
  <GlobalStateProvider history={history}>
    <Router history={history}>
      <App>
        <div className="h-screen flex flex-col">
          <Navigation />
          <Switch>
            <Route path="/reports" component={Reports} />
            <Route path="/" component={Story} />
          </Switch>
        </div>
      </App>
    </Router>
  </GlobalStateProvider>
);

window.renderTestDashboard = (containerId, history) => {
  ReactDOM.render(
    routing,
    document.getElementById(containerId)
  );
  serviceWorker.unregister();
};


// Mount to root if it is not a micro frontend
if (!document.getElementById('TestDashboard-container')) {
  ReactDOM.render(routing, document.getElementById("root"));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
