import React, { useState } from "react";
import ReportsDatePicker from "./ReportsDatePicker";
import ReportsWeekPicker from "./ReportsWeekPicker";
import { datesAreEqual } from "../utils";

export default function ReportsNavigation({
  reports,
  report,
  setReport,
  setOpen,
}) {
  const [section, setSection] = useState("daily");
  const dailyReports = reports
    ? reports.filter((r) => {
        return r.timeframe === "D";
      })
    : [];
  const weeklyReports = reports
    ? reports.filter((r) => {
        return r.timeframe === "W";
      })
    : [];
  return (
    <div className="rounded-bl-lg pb-24 bg-white">
      <div className="w-full border-b">
        <div className="flex justify-center">
          <div className="flex flex-row h-20 sections">
            <a
              href="./"
              className={`h-full text-center flex items-center relative`}
              aria-current={section === "daily" ? "page" : undefined}
              onClick={(e) => {
                e.preventDefault();
                setSection("daily");
              }}
            >
              <div className="px-6">Daily</div>
            </a>
            <a
              href="./"
              className={`h-full text-center flex items-center relative ${
                weeklyReports.length === 0 && "cursor-default opacity-20"
              }`}
              aria-current={section === "weekly" ? "page" : undefined}
              onClick={(e) => {
                e.preventDefault();
                weeklyReports.length && setSection("weekly");
              }}
            >
              <div className={`px-6`}>Weekly</div>
            </a>
          </div>
        </div>
      </div>
      {section === "daily" && (
        <div className="">
          {dailyReports && report && (
            <ReportsDatePicker
              setValue={(value) => {
                setReport(
                  dailyReports.find((r) => {
                    return datesAreEqual(r.date, value);
                  })
                );
                setOpen(false);
              }}
              value={report ? report.date : null}
              availableDays={
                dailyReports
                  ? dailyReports.map((r) => {
                      return r.date;
                    })
                  : []
              }
            />
          )}
        </div>
      )}
      {section === "weekly" && (
        <div className="">
          {weeklyReports && report && (
            <ReportsWeekPicker
              setValue={(value) => {
                setReport(
                  weeklyReports.find((r) => {
                    return datesAreEqual(r.date, value);
                  })
                );
                setOpen(false);
              }}
              value={report ? report.date : null}
              availableDays={
                weeklyReports
                  ? weeklyReports.map((r) => {
                      return r.date;
                    })
                  : []
              }
            />
          )}
        </div>
      )}
    </div>
  );
}
