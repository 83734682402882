import NumberFormat from "react-number-format";

export const formatData = (data) => {
  if (data === "None") {
    return <span className="text-gray-400">–</span>;
  } else if (isNaN(data)) {
    return data;
  } else {
    return (
      <NumberFormat
        value={data}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
      />
    );
  }
};

export const getAllMonthDates = (date) => {
  const from = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0);
  const to = new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0);
  let days = [];
  for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
    days.push(new Date(day));
  }
  return days;
};

export const getAllWeekDates = (date) => {
  const from = mondayThisWeek(date);
  let days = [];
  for (var i = 0; i < 7; i++) {
    const day = new Date(from);
    days.push(new Date(day.setDate(day.getDate() + i)));
  }
  return days;
};

export const mondayThisWeek = (date) => {
  const _date = new Date(date);
  const day = _date.getDay();
  const diff = _date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(_date.setDate(diff));
};

export const datesAreEqual = (day, otherDay) => {
  return (
    day.getFullYear() === otherDay.getFullYear() &&
    day.getMonth() === otherDay.getMonth() &&
    day.getDate() === otherDay.getDate()
  );
};

export const listHasDate = (dateList, day) => {
  return dateList.find((date) => {
    return datesAreEqual(date, day);
  });
};

export const isSameMonth = (day, otherDay) => {
  return (
    otherDay.getFullYear() === day.getFullYear() &&
    otherDay.getMonth() === day.getMonth()
  );
};

export const listHasMonth = (dateList, day) => {
  return dateList.find((date) => {
    return isSameMonth(date, day);
  });
};
