import React, { useContext, useState, useEffect } from "react";
import TimeSlider from "./TimeSlider";
import StoryList from "./StoryList";
import Data from "./Data";

import Context from "../store/context";
import "./Story.scss";

export default function Story(props) {
  const { globalState, globalDispatch } = useContext(Context);

  const [view, setView] = useState("list");
  const [isPanelOpen, setPanelOpen] = useState(false);

  useEffect(() => {
    // globalDispatch({type: "storyIndex", payload: 0})
  }, [globalState.stories]);

  useEffect(() => {
    // globalDispatch({type: "storyIndex", payload: 0})
  }, [globalState.time]);

  const findStoryIndex = (class_name) => {
    for (var i = 0; i < globalState.stories.length; i++) {
      if (globalState.stories[i].class_name === class_name) {
        return i;
      }
    }
    return 0;
  };

  const handleStorySelect = (story) => {
    globalDispatch({ type: "storyIndex", payload: story.class_name });
    setView("story");
  };

  return (
    <div className="flex flex-grow flex-col">
      <div className="flex flex-row flex-grow">
        <div className="flex flex-1 flex-col">
          <div className="flex justify-between w-full">
            {view === "story" && (
              <button
                className="p-12"
                onClick={() => setView(view === "list" ? "story" : "list")}
              >
                <img
                  alt=""
                  src="/assets/Control/Close.png"
                  srcSet="/assets/Control/Close.png 1x, /assets/Control/Close@2x.png 2x, /assets/Control/Close@3x.png 3x"
                />
              </button>
            )}
          </div>

          <div className="flex flex-grow flex-wrap overflow-y-scroll no-scrollbar relative">
            <div>
              <div
                className={`flex flex-grow flex-col justify-center h-full ${
                  view === "list" ? "hidden" : ""
                }`}
              >
                <h1
                  className={`story-title text-8xl leading-tight font-thin px-12`}
                  dangerouslySetInnerHTML={{
                    __html:
                      globalState.stories.length > 0 &&
                      globalState.storyIndex !== null
                        ? globalState.stories[
                            findStoryIndex(globalState.storyIndex)
                          ].story[globalState.time].micro
                        : "",
                  }}
                ></h1>
              </div>
              <div>
                <StoryList
                  onSelect={handleStorySelect}
                  onClose={() => setView("story")}
                  className={`${
                    view === "story" ? "hidden" : ""
                  } absolute w-full`}
                />
              </div>
            </div>
          </div>
        </div>

        <Data
          story={globalState.stories[findStoryIndex(globalState.storyIndex)]}
          isOpen={isPanelOpen}
          onToggle={() => setPanelOpen(!isPanelOpen)}
        />
      </div>
      <div className="py-8 px-6 border-t">
        <TimeSlider />
      </div>
    </div>
  );
}
