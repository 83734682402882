import React, { useEffect, useState, useContext } from "react";
import Slider from "rc-slider";
import styled from "styled-components";
import { Handle } from "rc-slider";
import Moment from "moment";
import { CalendarIcon, ReloadIcon } from "./Icons";
import DateModal from "./DateModal";

import Context from "../store/context";

import "rc-slider/assets/index.css";
import "../styles/TimeSlider.scss";

export const Reloader = (props) => {
  const { globalState, globalDispatch } = useContext(Context);

  return (
    <button
      className={`${
      globalState.refetch ? "animate-spin" : ""
      } `}
      onClick={() => {
        globalState.refetch === false && globalDispatch({ type: "refetch", payload: true });
      }}
    >
      <ReloadIcon/>
    </button>
  )
}

const Mark = (props) => {
  return (
    <div
      key={props.index}
      className={`timeline-bars m-px flex flex-grow items-end h-5 opacity-100`}
    >
      {props.data.map((value, index) => {
        return (
          <div
            key={index}
            className={`${
              props.legend[index] ? "visible" : "invisible"
            } w-full opacity-25 `}
            style={{ height: value * 0.6 + "px" }}
          ></div>
        );
      })}
    </div>
  );
};

const Timeline = (props) => {
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    if (!props.data || !props.data.signals) return;

    let marks = [];

    for (var i = 0; i < props.data.signals.length; i++) {
      marks.push(
        Mark({
          index: i,
          data: props.data.signals[i],
          legend: props.legend,
        })
      );
    }

    setTimeline(marks);
  }, [props.data, props.legend]);

  return timeline;
};

const FlexHandle = styled(Handle)`
  display: flex;
  justify-content: center;
`;

const Value = styled.div`
  margin-top: -28px;
  white-space: nowrap;
  color: white;
  font-size: 11px;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 4px;
  height: 18px;
  border-radius: 4px;
`;

export default function TimeSlider(props) {
  const { globalState, globalDispatch } = useContext(Context);

  const [value, setValue] = useState(0);
  const [max, setMax] = useState(0);
  const [legend, setLegend] = useState([]);
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);

  useEffect(() => {
    globalDispatch({ type: "time", payload: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!globalState.data || !globalState.data.timeline) return;

    setMax(globalState.data.timeline.signals.length - 1);
    setLegend(Array(globalState.data.timeline.labels.length).fill(true));
  }, [globalState.data]);

  const MyHandle = (props) => {
    const { value, dragging, index, ...rest } = props;

    return (
      <FlexHandle {...rest}>
        {dragging ? (
          <Value>
            {globalState.data.times ? globalState.data.times[value] : ""}
          </Value>
        ) : (
          ""
        )}
      </FlexHandle>
    );
  };

  const handleLegendClick = (i) => {
    var legendValues = legend;
    legendValues[i] = !legendValues[i];
    setLegend([...legendValues]);
  };

  return (
    <div className={props.className}>
      {dateTimePickerOpen && (
        <DateModal
          value={globalState.date}
          onClose={() => {
            setDateTimePickerOpen(false);
          }}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Reloader />
          <button
            className="flex items-center"
            onClick={() => {
              setDateTimePickerOpen(true);
            }}
          >
            
            <CalendarIcon />
            <div className="ml-2 font-medium">
              {new Moment(globalState.date).format("dddd MMMM Do, YYYY")}
            </div>
          </button>
        </div>
        <div>
          <ul className="h-full flex flex-wrap items-center text-xs font-medium timeline-key">
            {(globalState.data.timeline
              ? globalState.data.timeline.labels
              : []
            ).map((key, index) => {
              return (
                <li
                  key={index}
                  onClick={() => handleLegendClick(index)}
                  className={`${
                    legend[index] ? "" : "text-gray-400"
                  } cursor-pointer pr-4 flex items-center`}
                >
                  <div
                    className={`${
                      legend[index] ? "" : "bg-gray-400"
                    } legend-mark ${key.toLowerCase()}-gradient inline-block mr-2`}
                  />
                  <div>{key.toUpperCase()}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row pt-20">
          <Timeline legend={legend} data={globalState.data.timeline} />
        </div>

        <Slider
          handle={MyHandle}
          onChange={(v) => {
            setValue(v);
          }}
          value={value}
          min={0}
          max={max}
          defaultValue={100}
        />
      </div>
      <div className="flex text-xs font-medium">
        <div className="w-1/2">
          {globalState.data.times ? globalState.data.times[0] : ""}
        </div>
        <div className="w-1/2 text-right">
          {globalState.data.times
            ? globalState.data.times[globalState.data.times.length - 1]
            : ""}
        </div>
      </div>
    </div>
  );
}
