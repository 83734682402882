import React, { useState } from "react";
import DayPicker from "react-day-picker";
import styles from "./ReportsWeekPicker.module.css";
import { MinusIcon, PlusIcon } from "./Icons";
import {
  getAllMonthDates,
  getAllWeekDates,
  datesAreEqual,
  listHasMonth,
  listHasDate,
  isSameMonth,
} from "../utils";
import { isSameWeek } from "date-fns";

const ReportsWeekPicker = ({ availableDays, value, setValue }) => {
  let months = [];
  availableDays.forEach((day) => {
    if (!listHasMonth(months, day)) months.push(day);
  });

  let defaultMonth = months[0];
  if (value) {
    defaultMonth =
      months.find((month) => {
        return isSameMonth(value, month);
      }) || defaultMonth;
  }

  const [openMonth, setOpenMonth] = useState(defaultMonth);

  let disabledDays = [];
  if (openMonth) {
    // react-day-picker wants disabledDays but we have availableDays, reverse this
    getAllMonthDates(openMonth).forEach((day) => {
      const isInAvailableDays = availableDays.find((availableDay) => {
        return isSameWeek(availableDay, day, { weekStartsOn: 1 });
      });
      if (!isInAvailableDays) {
        disabledDays.push(day);
      }
    });
  }

  return (
    <div className="mx-auto py-4">
      {months.map((month) => {
        const isOpen = openMonth ? datesAreEqual(month, openMonth) : false;
        return (
          <div key={month} className="p-4 border-b">
            <div className="flex items-center">
              <div className="flex-grow">
                {month.toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                })}
              </div>
              <button
                onClick={() => {
                  setOpenMonth(isOpen ? undefined : month);
                }}
              >
                {isOpen ? <MinusIcon /> : <PlusIcon />}
              </button>
            </div>
            {isOpen && (
              <div className="py-4">
                <DayPicker
                  classNames={styles}
                  navbarElement={<div></div>}
                  canChangeMonth={false}
                  showWeekNumbers={true}
                  firstDayOfWeek={1}
                  onDayClick={(day, props) => {
                    if (props[styles.disabled]) {
                      return;
                    }
                    // no globalDispatch called because we don't want week numbers to affect the global date
                    day.setHours(0); // react-day-picker sets the day hours to 12, revert to 0
                    const foundDay = availableDays.find((availableDay) => {
                      return isSameWeek(day, availableDay);
                    });
                    if (foundDay) {
                      setValue(foundDay);
                    }
                  }}
                  modifiersStyles={{
                    "--color-primary": "#3f5efb",
                  }}
                  initialMonth={month}
                  disabledDays={disabledDays}
                  selectedDays={value ? getAllWeekDates(value) : []}
                  onWeekClick={(weekNumber, days) => {
                    let pickedDay;
                    days.forEach((d) => {
                      pickedDay = availableDays.find((ad) => {
                        return datesAreEqual(d, ad);
                      });
                    });
                    if (pickedDay) {
                      setValue(pickedDay);
                    }
                  }}
                  renderWeek={(weekNumber, week) => {
                    let className = [styles.weekNumberInner];
                    if (value && !listHasDate(week, value)) {
                      className.push(styles.disabled);
                    }
                    return (
                      <div className={className.join(" ")}>{weekNumber}</div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReportsWeekPicker;
