import Context from "../store/context";
import React, { useState, useEffect, useContext } from "react";
import Loading from "./Loading";
import { getReport } from "../api";
import Moment from "moment";
import { CalendarIcon, ChainIcon, CheckmarkIcon, ReloadIcon } from "./Icons";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";

const ReportReloader = (props) => {
  const { globalState, globalDispatch } = useContext(Context);

  return (
    <button
      className={`${
      globalState.reportRefetch ? "animate-spin" : ""
      } `}
      onClick={() => {
        globalState.reportRefetch === false && globalDispatch({ type: "reportRefetch", payload: true });
      }}
    >
      <ReloadIcon/>
    </button>
  )
}

const XAxisTick = (tick) => {
  const hasTextLabel = isNaN(Number(tick.value));
  if (!hasTextLabel) return null;
  return (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: "#6B7280",
          fontSize: 10,
        }}
      >
        {tick.value}
      </text>
    </g>
  );
};

const BlueGradient = (props) => (
  <linearGradient id="blue" x1="0" x2="0" y1="0" y2="1">
    <stop offset="0%" stopColor="#557FF0" stopOpacity="100" />
    <stop offset="70%" stopColor="#BEB1CE" stopOpacity="100" />
    <stop offset="100%" stopColor="#ECC7BF" stopOpacity="100" />
  </linearGradient>
);

const GreenGradient = (props) => (
  <linearGradient id="green" x1="0" x2="0" y1="0" y2="1">
    <stop offset="0%" stopColor="#74D944" stopOpacity="100" />
    <stop offset="100%" stopColor="#F0A3B5" stopOpacity="100" />
  </linearGradient>
);

const PinkGradient = (props) => (
  <linearGradient id="pink" x1="0" x2="0" y1="0" y2="1">
    <stop offset="0%" stopColor="#D94F43" stopOpacity="100" />
    <stop offset="100%" stopColor="#D4BEFD" stopOpacity="100" />
  </linearGradient>
);

const CustomBarComponent = ({ x, y, width, height, color, children }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <defs>{children}</defs>
      <rect y={10} width={width} height={height} fill={`url(#${color})`} />
    </g>
  );
};

const seriesColors = ["#557FF0", "#74D944", "#D94F43"];

const BarChart = ({ data, color }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["value"]}
      indexBy="label"
      margin={{ top: 0, right: 20, bottom: 30, left: 20 }}
      enableLabel={false}
      padding={0.2}
      enableGridY={false}
      animate={false}
      colors={color}
      axisLeft={{ format: () => null, tickSize: 0 }}
      axisBottom={{
        legend: null,
        tickSize: 0,
        renderTick: XAxisTick,
      }}
      barComponent={(props) => {
        let Gradient = BlueGradient;
        if (props.color === "green") {
          Gradient = GreenGradient;
        } else if (props.color === "pink") {
          Gradient = PinkGradient;
        }
        return (
          <CustomBarComponent {...props}>
            <Gradient />
          </CustomBarComponent>
        );
      }}
    />
  );
};

const CustomSymbol = ({ size, color, borderWidth, borderColor, datum }) => {
  const sizeBase = datum.highlight ? 2.0 : 1.0;
  return (
    <g>
      <circle
        fill="#fff"
        r={(size / 2) * sizeBase}
        strokeWidth={borderWidth}
        stroke={borderColor}
      />
      <circle
        r={(size / 3) * sizeBase}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fill={color}
      />
    </g>
  );
};

const LineChart = ({ data, yMin, yMax }) => {
  return (
    <ResponsiveLine
      data={data}
      keys={["value"]}
      indexBy="label"
      margin={{ top: 10, right: 10, bottom: 55, left: 10 }}
      enableLabel={false}
      enableGridY={false}
      pointSymbol={CustomSymbol}
      animate={false}
      colors={seriesColors}
      pointBorderWidth={0}
      axisLeft={{ format: () => null, tickSize: 0 }}
      yScale={{
        type: "linear",
        stacked: false,
        min: yMin,
        max: yMax,
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          translateY: 55,
          translateX: 0,
          itemHeight: 20,
          itemWidth: 95,
          itemsSpacing: 4,
        },
      ]}
      markers={[
        {
          axis: "y",
          value: 0,
          lineStyle: { stroke: "black", strokeWidth: 1 },
          legend: "",
          legendPosition: "bottom-left",
        },
      ]}
    />
  );
};

const Story = ({ copy, trend, isLast }) => {
  return (
    <div
      className={`flex items-top font-light leading-relaxed pb-2 mb-2 ${
        !isLast && "border-b border-gray-100"
      }`}
    >
      <div
        className="flex-grow pr-4 text-lg"
        dangerouslySetInnerHTML={{ __html: copy }}
      ></div>
      <div
        className={`leading-loose ${
          trend > 0 ? "text-trendup" : "text-trenddown"
        }`}
      >
        {(trend > 0 && "▲") || "▼"}
        <label className="pl-1">{trend}%</label>
      </div>
    </div>
  );
};

const Card = ({ title, copy, highlight, data }) => {
  let chartData = [];
  let yMin = 0;
  let yMax = 0;
  let seriesLabels = [];
  data.series.forEach((series, i) => {
    let x_labels = null;
    let label = "";
    let seriesHighlights = data.highlights ? data.highlights[i] : [];
    if (data.series_labels) {
      x_labels = data.series_labels["x"];
      label = data.series_labels["y"];
    } else {
      label = data.labels ? data.labels[i] : "";
    }
    // Make sure series labels are unique
    if (seriesLabels.indexOf(label) === -1) {
      seriesLabels.push(label);
    } else {
      label = `${label} ${i}`;
    }
    let seriesData = [];
    series.forEach((value, j) => {
      if (value < yMin) {
        yMin = value;
      }
      if (value > yMax) {
        yMax = value;
      }
      seriesData.push({
        y: value,
        x: x_labels ? x_labels[j] : String(j),
        highlight: seriesHighlights.indexOf(j) !== -1,
      });
    });
    chartData.push({ id: label, data: seriesData });
  });
  return (
    <div
      className={`border p-4 flex flex-col ${
        highlight ? "bg-nikedark text-white" : ""
      }`}
    >
      <div className="uppercase text-xs tracking-wider mb-8">{title}</div>
      <div
        className="flex-grow mb-8 leading-relaxed font-light"
        dangerouslySetInnerHTML={{ __html: copy }}
      ></div>
      <div className={`${highlight ? "bg-white" : ""} h-36`}>
        <LineChart data={chartData} yMin={yMin} yMax={yMax} />
      </div>
    </div>
  );
};

const Section = ({ name, stories, cards }) => {
  return (
    <div className="mb-8">
      <h1 className="font-semibold text-4xl mt-16 mb-12">{name}</h1>
      {stories.map((story, i) => {
        return <Story key={i} isLast={i === stories.length - 1} {...story} />;
      })}
      <div className="my-8 sm:my-16">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
          {cards.map((card, i) => {
            return <Card key={i} {...card} />;
          })}
        </div>
      </div>
    </div>
  );
};

const CopyWidget = () => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <button
      className={`rounded flex items-center px-4 py-2 text-sm w-32 ${
        isCopied ? "bg-black text-white" : "bg-gray-100 text-gray-700"
      }`}
      onClick={async () => {
        if (isCopied) return;
        await navigator.clipboard.writeText(window.location);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      }}
    >
      <div className="pr-1 text-center flex-grow whitespace-nowrap">
        {isCopied ? "Copied" : "Copy Link"}
      </div>{" "}
      {isCopied ? <CheckmarkIcon /> : <ChainIcon />}
    </button>
  );
};

const Inner = ({ metadata, sections, activity, setOpen }) => {
  const date = Moment(metadata.date);
  return (
    <div>
      <div className="mb-8 pb-8 border-b border-black flex items-center">
        <div className="md:hidden mr-4">
          <button
            onClick={() => {
              setOpen(true);
            }}
            className="border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 flex"
          >
            <span className="sr-only">Open sidebar</span>
            <CalendarIcon />
          </button>
        </div>
        <div className="flex-grow leading-tight font-medium">
          <ReportReloader/>
          <div className="">{date.format("dddd")}</div>
          <div className="">{date.format("MMMM D, YYYY")}</div>
        </div>
        <div>
          <CopyWidget />
        </div>
      </div>
      <div className="mb-12">
        {sections.map((s, i) => {
          return (
            <div key={i}>
              <Section key={s.id} {...s} />
              <div className="border-b border-black" />
            </div>
          );
        })}
      </div>
      <div className="mb-8">
        <h1 className="font-semibold text-4xl mt-16 mb-12">Activity</h1>
        {activity.map((a, i) => {
          const color = ["blue", "green", "pink"][i % 3];
          return (
            <div key={i} className="mb-4">
              <div className="text-lg my-2">{a.title}</div>
              <div className="h-28 w-full">
                <BarChart
                  color={color}
                  data={a.data.map((value, i) => {
                    return {
                      value: value,
                      label: a.labels[i] || String(i),
                    };
                  })}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default function Report({ id, location, setOpen }) {
  const { globalState, globalDispatch } = useContext(Context);

  const [report, setReport] = useState({
    loading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    async function doFetch() {
      try {
        const data = await getReport(location, id, {
          refetching: globalState.reportRefetch,
          extrapolated:  globalState.extrapolated
        });
        globalDispatch({ type: "reportRefetch", payload: false });
        setReport({ loading: false, data: data, error: null });
      } catch (error) {
        setReport({ loading: false, data: null, error: error });
        console.error(error);
      }
    }
    doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location, globalState.extrapolated]);

  if (report.loading) {
    return <Loading />;
  }

  return (
    <div>
      {report.data && <Inner setOpen={setOpen} {...report.data} />}
      <div className="mt-48">
        <svg
          width="32"
          height="12"
          viewBox="0 0 32 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.53175 11.4008C2.58028 11.3671 1.79722 11.1061 1.19097 10.6177C1.07309 10.5251 0.795229 10.2472 0.702608 10.1293C0.458426 9.8178 0.290025 9.51468 0.172144 9.17787C-0.173079 8.1422 0.00374222 6.78657 0.677348 5.29622C1.25833 4.02479 2.15086 2.76178 3.70857 1.01041C3.93591 0.749386 4.61794 0 4.62636 0C4.62636 0 4.59268 0.0673603 4.54216 0.143141C4.138 0.825166 3.79277 1.61665 3.59911 2.31552C3.29599 3.42697 3.32967 4.37844 3.70857 5.1194C3.96959 5.62461 4.40744 6.07087 4.90422 6.31505C5.77149 6.74447 7.05134 6.77815 8.60063 6.41609C8.71009 6.39083 14.0147 4.98468 20.3887 3.28383C26.7796 1.58297 32 0.193662 32 0.193662C32 0.193662 17.1807 6.53397 9.48474 9.82622C8.26383 10.3483 7.94387 10.4746 7.3713 10.6766C5.90621 11.1903 4.59268 11.4429 3.53175 11.4008Z"
            fill="#06080D"
          />
        </svg>
      </div>
    </div>
  );
}
