import React from "react";
import "./Toggle.scss";

export default class Toggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.getDefaultSelected(),
    };
  }

  getDefaultSelected() {
    if (typeof this.props.selected !== 'undefined') {
      return this.props.selected;
    }

    return this.props.values[0];
  }

  handleChange(event) {
    this.setState(
      {
        selected:
          this.state.selected === this.props.values[0]
            ? this.props.values[1]
            : this.props.values[0],
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.selected);
        }
      }
    );
  }

  render() {
    return (
      <div className="toggle border rounded-full border-black inline-block py-2 select-none">
        <input
          type="checkbox"
          className="hidden"
          name={this.props.name}
          id={this.props.name}
          checked={this.state.selected === this.props.values[0]}
          onChange={(e) => this.handleChange(e)}
        />
        <label
          className="cursor-pointer toggle-switch-label"
          htmlFor={this.props.name}
        >
          <span className="rounded-full py-2 px-6">{this.props.values[0]}</span>
          <span className="rounded-full py-2 px-6 ">{this.props.values[1]}</span>
        </label>
      </div>
    );
  }
}
