import React, { useContext, useState } from "react";
import Context from "../store/context";
import DayPicker from "react-day-picker";
import styles from "./ReportsDatePicker.module.css";
import { MinusIcon, PlusIcon } from "./Icons";
import {
  getAllMonthDates,
  datesAreEqual,
  listHasMonth,
  isSameMonth,
} from "../utils";

const ReportsDatePicker = ({ availableDays, value, setValue }) => {
  const { globalDispatch } = useContext(Context);

  let months = [];
  availableDays.forEach((day) => {
    if (!listHasMonth(months, day)) months.push(day);
  });

  const [openMonth, setOpenMonth] = useState(
    value
      ? months.find((month) => {
          return isSameMonth(value, month);
        })
      : months
      ? months[0]
      : null
  );

  let disabledDays = [];
  if (openMonth) {
    // react-day-picker wants disabledDays but we have availableDays, reverse this
    getAllMonthDates(openMonth).forEach((day) => {
      const isInAvailableDays = availableDays.find((availableDay) => {
        // this is how you compare dates
        return datesAreEqual(availableDay, day);
      });
      if (!isInAvailableDays) {
        disabledDays.push(day);
      }
    });
  }

  return (
    <div className="mx-auto py-4">
      {months.map((month) => {
        const isOpen = openMonth ? datesAreEqual(month, openMonth) : false;
        return (
          <div key={month} className="p-4 border-b">
            <div className="flex items-center">
              <div className="flex-grow">
                {month.toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                })}
              </div>
              <button
                onClick={() => {
                  setOpenMonth(isOpen ? undefined : month);
                }}
              >
                {isOpen ? <MinusIcon /> : <PlusIcon />}
              </button>
            </div>
            {isOpen && (
              <div className="py-4">
                <DayPicker
                  classNames={styles}
                  navbarElement={<div></div>}
                  canChangeMonth={false}
                  onDayClick={(day, props) => {
                    if (props[styles.disabled]) {
                      return;
                    }
                    globalDispatch({
                      type: "date",
                      payload: day,
                    });
                    day.setHours(0); // react-day-picker sets the day hours to 12, revert to 0
                    setValue(day);
                  }}
                  modifiersStyles={{
                    "--color-primary": "#3f5efb",
                  }}
                  initialMonth={month}
                  disabledDays={disabledDays}
                  selectedDays={value ? [value] : []}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReportsDatePicker;
