export const LOCATIONS = [
    { value: "Seoul", code: "SEL", label: "Seoul" },
    { value: "Tokyo", code: "TOK", label: "Tokyo" },
    { value: "Los Angeles", code: "LAX", label: "Los Angeles" },
    { value: "London", code: "LDN", label: "London" },
    { value: "Portland", code: "PDX", label: "Portland" },
    { value: "Miami", code: "MIA", label: "Miami" },
    { value: "Dallas", code: "DFW", label: "Dallas" },
    { value: "Seattle", code: "SEA", label: "Seattle" },
    { value: "Toronto", code: "YYZ", label: "Toronto" },
    { value: "Montreal", code: "YUL", label: "Montreal" },
    { value: "Detroit", code: "DTW", label: "Detroit" },
    { value: "Paris", code: "CDG", label: "Paris" },
    { value: "Philadelphia", code: "PHL", label: "Philadelphia" },
    { value: "Minneapolis", code: "MSP", label: "Minneapolis" },
    { value: "vanilla", code: "SEL", label: "Hongdae", isDisabled: true },
    { value: "vanilla", code: "BER", label: "Berlin", isDisabled: true },
    { value: "vanilla", code: "PVG", label: "Shanghai", isDisabled: true },
    { value: "vanilla", code: "MXP", label: "Milan", isDisabled: true },
];
